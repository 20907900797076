<template>
	<div>
		<div class="modal-header">
			<div class="modal-title">
				<a-icon type="medicine-box" style="margin-right:10px" />
				<span>{{ l('确认作废问题') }}</span>
			</div>
		</div>
		<div>
			<a-config-provider :locale="zh_CN">
				<a-row>
					<!-- <a-col :span="24">
						<a-form-item :label-col="{ span: 6 }" :wrapper-col="{ span: 17 }" :label="l('Completetime')">
							<a-date-picker v-model="closeTime" :placeholder="l('select_date')" style="width: 100%" />
						</a-form-item>
					</a-col> -->

					<a-col :span="24">
						<a-form-item :label-col="{ span: 5 }" :wrapper-col="{ span: 17 }" :label="l('作废')">
							<a-textarea v-model="remark"
								:placeholder="l('请输入作废备注')" style="width: 100%"></a-textarea>
						</a-form-item>
					</a-col>
				</a-row>
			</a-config-provider>
		</div>
		<div class="modal-footer" v-if="!isLook">
			<a-button @click="close">
				{{ l('Cancel') }}
			</a-button>
			<a-button :type="'primary'" @click="save">
				{{ l('Save') }}
			</a-button>
		</div>
	</div>
</template>

<script>
import ModalComponentBase from '../../../../shared/component-base/modal-component-base';
import zh_CN from 'ant-design-vue/lib/locale/zh_CN';
import { IssueServiceProxy } from '../../../../shared/service-proxies';

let _this;
export default {
	name: 'Nullify',
	mixins: [ModalComponentBase],
	components: {

	},
	created() {
		this.fullData(); // 模态框必须,填充数据到data字段
		this.IssueServiceProxy = new IssueServiceProxy(this.$apiUrl, this.$api);
	},
	mounted() {
		this.getRemark();
	},
	data() {
		return {
			zh_CN,
			isLook: false,//是否是查看
			isremark: false,
			remark: undefined,
			closeTime:undefined,
		}
	},
	methods: {
		save() {
			
				this.closeissue();
		},
		closeissue() {
			this.isLoading = true;
			this.IssueServiceProxy.issueNullify(
				this.id,
				this.remark
			).finally(() => {
				this.close();
			}).then(res => {
				this.success(true);
			})
		},
	}
}
</script>

<style></style>
